<template>
  <div class="Article">
    <homeTop> </homeTop>

    <div class='main'>
      <h3 class='title'>{{article.title}}</h3>

      <p class='date'> {{$store.state.date(article.update) }} · {{article.read}}阅读 · 发布于{{$store.state.date(article.createdAt) }}  </p>

      <p class='content' v-html="article.content"> </p>

    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTop from '@/views/children/home_top'

export default {
  name: 'Article',
  components: {
    homeTop,

  },
  props: [
    'articleId'
  ],
  data() {
    return {
      article: {}
    }
  },
  methods: {
    async getArticle() {
      let res = await request({
        url: '/article/getById',
        data: {
          id: this.$route.query.id
        }
      })
      this.article = res.data.data
    }
  },
  async created() {
    this.getArticle()

  },
}
</script>

<style lang="scss" scoped>
.main {
  padding: 20px;
  .title{
      color: rgb(30, 30, 30);
      font-size: 22px;
  }
  .content{
      margin-top: 20px;
      color: rgb(47, 47, 47);
  }
}
.date{
  color: rgb(161, 161, 161);
  font-size: 12px;
  margin-top: 10px;
}
</style>
